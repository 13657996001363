<template>
  <div>
    <GraphsMenu menu="general" type="customer"></GraphsMenu><br />
    <CCard>
    <CCardHeader>
      <slot name="header">
        Resumen General
        <div class="card-header-actions" style="width: 300px; margin-right: 4px;">
          <CRow>      
            <CCol md="4" style="text-align: right;"></CCol>
            <CCol md="8" style="text-align: right;">
              <CSelect class="m-0"
                :value.sync="monthSelect" 
                :options="monthsOptions"
                @change="changeFilters"
              />  
            </CCol>     
          </CRow>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>      
        <CCol md="3">
          <CDataTable 
            :hover="true"
            :striped="true"
            :border="true"
            :small="true"
            :fixed="true"
            :items="collaborators"
            :fields="fields"
            :items-per-page="10"
            :dark="false"
            pagination
            style="width: 100%;"
          >
            <template #position="{item}"><td class="w50 text-center">{{item.position}}</td></template>
            <template #name="{item}"><td>{{item.collaborator_name}}</td></template>
            <template #total="{item}">
              <td class="text-center" :class="item.color">
                {{item.average}}
              </td>
            </template>
          </CDataTable>
        </CCol>
        <CCol md="9">
            <CRow>           
              <CCol md="4">
                <CWidgetSimple style="height: 125px;" header="Totales" :text="String(metrics.total)"></CWidgetSimple>
              </CCol>     
              <CCol md="4">
                <CWidgetProgress
                  style="height: 125px;"
                  :header="String(metrics.finished)"
                  text="Realizadas"
                  color="success"
                  :value="Number(metrics.finished_percent)"
                  >
                  </CWidgetProgress>
              </CCol>  
              <CCol md="4">
                <CWidgetProgress
                  style="height: 125px;"
                  :header="String(metrics.left)"
                  text="Faltantes"
                  color="dark"
                  :value="Number(metrics.left_percent)"
                  >
                  </CWidgetProgress>
              </CCol>   
            </CRow>
            <CRow>           
              <CCol md="4">
                <GaugeSemaforoGraph ref="graphGauge"></GaugeSemaforoGraph>
              </CCol>  
              <CCol md="4">
                <GaugePercentSemaforoGraph ref="graphPercent"></GaugePercentSemaforoGraph>
              </CCol>  
              <CCol md="4">
                <DonoutSemaforoGraph ref="graphDonout"></DonoutSemaforoGraph>
              </CCol>  
            </CRow>
        </CCol>          
      </CRow>
    </CCardBody>
  </CCard>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/graphs';
import DonoutSemaforoGraph from './graphs/DonoutSemaforo.vue';
import GaugeSemaforoGraph from './graphs/GaugeSemaforo.vue';
import GaugePercentSemaforoGraph from './graphs/GaugePercentSemaforo.vue';
import GraphsMenu from './GraphsMenu.vue';

export default {
  name: 'CustomerGraphsHome',
  components: {
    DonoutSemaforoGraph,
    GaugeSemaforoGraph,
    GaugePercentSemaforoGraph,
    GraphsMenu
  },
  data () {
    return {
      code: 'general',
      collaborators: [],
      fields: [
          {key: 'position', label: ''},
          {key: 'name', label: 'Colaborador'},
          {key: 'total', label: 'Promedio'},
        ],
      
      customerSelect: "",
      customersOptions: [],
      monthSelect: "202409",
      monthsOptions: [],
      dataGraphs: [],
      graphs_gauge: 0,
      semaforo: 0,
      graphs_percent:0,
      graphs_donout:[{code: '',service : "",total : "0",}],
      metrics:{
        total : "0",
        finished : "0",
        finished_percent: "0",
        left : "0",
        left_percent : "0"
      }
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let is_customer = localStorage.getItem("is_customer");

    if(is_customer == "false"){
      let is_administrator = localStorage.getItem("is_administrator");

      if(is_administrator == "false"){
        let is_kam = localStorage.getItem("is_kam");

        if(is_kam == "false"){
          window.location.href = "/#/home"; this.loaded(); return;
        }
        else{
          window.location.href = "/#/welcome"; this.loaded(); return;
        }
      }
      else{
        window.location.href = "/#/graphs/general"; this.loaded(); return;
      }
    }

    let response = await ws.getCustomers(); 
    if(response.type == "success"){
      this.customersOptions = await this.$parseSelectOptions(response.data, "id", "name",[{"label": "Todos","value":""}]);
    }

    this.customerSelect = localStorage.getItem("customer_id")*1;

    let response2 = await ws.getMonths(); 
    if(response2.type == "success"){
      this.monthsOptions = await this.$parseSelectOptions(response2.data, "id", "name");
      if(response2.data.length > 0){
        this.monthSelect = this.monthsOptions[0].value;
      }      
    }

    await this.changeFilters();

    this.loaded();
  },
  methods: {
    async changeFilters () {
      this.loading();
        let filters = {
          customer_id : this.customerSelect,
          month : this.monthSelect,
        };
        let response = await ws.getMetrics(filters);
        if(response.type == "success"){
          this.metrics = response.data;          
        }
        let response_graphs = await ws.getGraphs(filters);
        if(response_graphs.type == "success"){
          this.dataGraphs = response_graphs.data;  
          this.collaborators = response_graphs.data.collaborators;        
          this.semaforo = response_graphs.data.semaforo;      
          this.graphs_percent = response_graphs.data.graph_percent;      
          this.graphs_donout = response_graphs.data.graph_donout;     
          this.graphs_gauge = response_graphs.data.graph_guage;
        }
        this.chargeGraphs();
      this.loaded();
    },
    async chargeGraphs(){
        await this.$refs.graphPercent.loadGraph(this.graphs_percent);
        await this.$refs.graphDonout.loadGraph(this.graphs_donout);
        await this.$refs.graphGauge.loadGraph(this.graphs_gauge);
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'danger'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'success'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
</style>
